$myFont: 'Poppins', Courier;

$colorBlack: #000000;
$colorWhite: #FFFFFF;
$colorBlue: #000562;

$colorBlackHover: #1a1d1e;
$colorBlueHover: #0d0933;

$colorBlackActive: #2f3438;
$colorBlueActive: #132454;