/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import '/src/app/sass/variables';

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.toast-success {
    background-color: #000562 !important;
}

.backgroundGrey {
    background-color: #F4F4F4 !important;
}

.footer {
    position: absolute;
    font-family: $myFont;
    left: 0;
    bottom: 0;
    width: 100%;
    color: #b9b9b9;
    text-align: center;
}

.pointer {
  cursor: pointer;
}

.dvmPopover {
  // border-radius: 12px;
  // padding: 4px;
  // max-width: 345px;
  display: none;

  &[data-show] {
    display: block;
  }
  &[data-popper-placement^='top'] {
    #arrow {
      bottom: 2px;
    }
  }
  &[data-popper-placement^='bottom'] {
    #arrow {
      top: 2px;
    }
  }
  #arrow {
    position: absolute;
    width: 12px;
    height: 12px;
    z-index: -1;
    &::before {
      position: absolute;
      width: 12px;
      height: 12px;
      z-index: -1;
      content: '';
      transform: rotate(45deg);
      background: black;
      // background: rgb(39, 39, 42);  // bg-gray-800
    }
  }
}
